import type { ColumnsType } from "antd/es/table";
import { InvoiceDataType } from "../models/InvoiceDataType";

const columns: ColumnsType<InvoiceDataType> = [
    {
        title: "Numéro de facture",
        dataIndex: "num",
        key: "num",
        sorter: false,
    },
        {
        title: "Date de facture",
        dataIndex: "date",
        key: "date",
        sorter: false,
    },
    {
        title:"Date d'échéance",
        dataIndex: "due_date",
        key: "due_date",
        sorter: false,
    },
    {
        title: "Contrat associé",
        dataIndex: "contracts",
        key: "contracts",
        sorter: false,
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        sorter: false,
    },

];

export { columns }