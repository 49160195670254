import { Icon } from "../ui/typo/Icon";
import { DatePicker } from "antd";
import './range-picker.scss';
import { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;
interface IProps{
    defaultValue?: any;
    onChange: (dateFrom?: Dayjs, dateTo?: Dayjs) => void;
}
export default function RangerPicker({ defaultValue, onChange }: IProps) {
    return (
        <div className="custom-range-picker">
            <div style={{position: 'absolute'}}>
                <Icon path="/icons/calendar.svg" size={20} className="left-icon" />
            </div>

            <RangePicker
                format={'DD/MM/YYYY'}
                suffixIcon={null}
                defaultValue={defaultValue}
                placeholder={[' --/--/---', ' --/--/---']}
                separator={<Icon path="/icons/arrow-right.svg" size={20} />}
                onChange={(dates, dateStrings) => {
                    if (dates && dates[0] && dates[1]) {
                        onChange(dates[0], dates[1]);
                    } else {
                        onChange();
                    }
                }}
            />
        </div>
    )
}