import { useEffect, useMemo, useState } from "react";
import Header from "../../components/layout/Header";
import factureService from "../../services/facture.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import DataPaginate from "../../models/utils/data-paginate";
import useTable from "../../utils/hooks/useTable";
import { columns } from "../../configs/InvoiceTableColumns";
import Facture from "../../models/facture";
import dateHelper from "../../utils/date.helper";
import { Button, Flex, Input, Layout, Select, Space, Table } from "antd";
import { Icon } from "../../components/ui/typo/Icon";
import useDebounce from "../../utils/hooks/useDebounce";
import RangerPicker from "../../components/form/range-picker";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import StatusInvoice from "../../components/core/road-folder/status-invoice";
import { StatusInvoice as StatusInvoiceEnum } from "../../models/enums/status-invoice.enum";


export default function ListInvoicesPage() {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [dataPaginate, setDatapaginate] = useState<DataPaginate>();
    const [page, setPage] = useState<number>(searchParams.has('page') ? parseInt(searchParams.get('page') || '1') : 1);
    const [search, setSearch] = useState<string>(searchParams.get('search') || '');
    const [status, setStatus] = useState<string>(searchParams.get('status') || '');
    const { visibleColumns, setVisibleColumns, renderColumns  } = useTable(columns)
    const navigate = useNavigate();
    const [dateFrom, setDateFrom] = useState<Dayjs | undefined>(
        searchParams.get("date_from") ? dayjs(searchParams.get("date_from"), "DD/MM/YYYY") : undefined
    );

    const [dateTo, setDateTo] = useState<Dayjs | undefined>(
        searchParams.get("date_to") ? dayjs(searchParams.get("date_to"), "DD/MM/YYYY") : undefined
    );


    useEffect(() => {
        const queryParams = new URLSearchParams();
        if (search) {
            queryParams.append('search', search);
        }

        if (status) {
            queryParams.append('status', status);
        }

        if (dateFrom) {
            queryParams.append('date_from', dateFrom.format('DD/MM/YYYY'));
        } else {
            queryParams.delete('date_from');
        }

        if (dateTo) {
            queryParams.append('date_to', dateTo.format('DD/MM/YYYY'));
        } else {
            queryParams.delete('date_to');
        }

        const queryParamsString = queryParams.toString();
        navigate(`?${queryParams.toString()}`);
        

        fetchData();

    }, [page, search, status, dateFrom, dateTo]);

    async function fetchData() {
        try {
            setLoading(true);
            const res = await factureService.list(page, search, status, dateFrom?.format('YYYY-MM-DD'), dateTo?.format('YYYY-MM-DD'));
            setDatapaginate(res);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

     async function onViewAttachment(url: string) {
        try {
            const response: any = await axios.get(url, {
                responseType: 'blob'
            });
            const blob = response.data;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            const parts = url.split("/");
            const filename = parts[parts.length - 1];
            link.download = `${filename}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Erreur lors du téléchargement du fichier :", error);
        }     
    }

     const dataTable = useMemo(() => {
        if (!dataPaginate || !dataPaginate.data) return [];
        return dataPaginate.data.map((item: Facture) => {
            return {
                id: item.id,
                num: item.num,
                date: dateHelper.dateToDDMMYYYY(item.date),
                due_date: dateHelper.dateToDDMMYYYY(item.due_date),
                contracts: item.contracts ? Object.values(item.contracts).filter(item => item != null).join(', ') : '-',
                status_code: item.status,
                status: <Flex justify="space-between" align="center">
                    <StatusInvoice facture={item} />
                    <Flex gap={20} align="center" >
                        <a target="_blank" href={item.public_url}><Icon path="/icons/eyes.svg" size={25} /></a>
                        <div style={{cursor: 'pointer'}} onClick={() => onViewAttachment(item.public_url)}><Icon path="/icons/download.svg" size={25} /></div>
                    </Flex>
                </Flex> 
            }
        });
     }, [dataPaginate]);
    
    const handleSearch = useDebounce((term) => {
        setPage(1);
        console.log(term);
        setSearch(term)
    }, 500);

    return <>
        <Header title="Factures" />

        <Layout className="container">
            <Flex vertical gap={16}>
                <Flex justify="space-between">
                    <Input
                        size="large"
                        placeholder="Rechercher..."
                        prefix={<Icon path="/icons/search-refraction.svg" size={20} />}
                        style={{ width: 320 }}
                        defaultValue={search ? search : undefined}
                        onChange={(e) => handleSearch(e.target.value)}
                    />

                    <Space size={8}>
                        <Select
                            showSearch
                            allowClear
                            placeholder="Filtrer par statut"
                            defaultValue={status}
                            onSearch={() => {}}
                            filterOption={ (input: any, option: any) =>
                                option && option.label && option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={[{ label: 'Payé', value: 'paid' }, { label: 'À payer', value: 'unpaid' }]}
                            style={{ width: 220 }}
                            onChange={(value) => {
                                setStatus(value);
                            }}
                        />

                        <RangerPicker
                            defaultValue={[dateFrom, dateTo]}
                            onChange={(dateFrom: Dayjs | undefined, dateTo: Dayjs | undefined) => {
                                setDateFrom(dateFrom);
                                setDateTo(dateTo);
                            }}
                        />

                    </Space>
                </Flex>
                
                <Table
                    rowKey={"id"}
                    loading={loading}
                    columns={renderColumns()}
                    dataSource={dataTable}
                    scroll={{ x: 1327, y: 500 }}
                    showSorterTooltip={false}
                    onRow={(record) => { return { onClick: () => { console.log('TODO') } } }}
                    pagination={{
                        current: page,
                        pageSize: dataPaginate?.per_page || 10,
                        total: dataPaginate?.total || 0,
                            onChange: (page) => {
                                setPage(page);
                            },
                    }}
                    rowClassName={(item) => item.status_code != StatusInvoiceEnum.PAID && new Date(item.due_date) > new Date() ? 'row-red' : ''}

                />
            </Flex>
        </Layout>
        
    </>
}