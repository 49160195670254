import type { ColumnsType } from "antd/es/table";

const columns: ColumnsType<any> = [
    {
        title: "Nom",
        dataIndex: "name",
        key: "name",
        sorter: false,
        width: 200
    },
        {
        title: "Type",
        dataIndex: "type",
        key: "type",
            sorter: false,
        width: 300
    },
];

export { columns }