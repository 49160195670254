import Facture from "../../../models/facture"

interface IProps{
    facture: Facture
}
export default function StatusInvoice({ facture }: IProps) {
    return (
        <div className="status-task" style={{
            borderColor: facture.getBorderColor(),
            backgroundColor: facture.getBackgroundColor(),
            color: facture.getColor(),
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 2,
            paddingBottom: 1,
            borderRadius: 20,            
        }}>
            {facture.getStatusLabel()}
        </div>
    )
}