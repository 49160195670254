import axios, { AxiosResponse } from "axios";
import DataPaginate from "../models/utils/data-paginate";
import Document from "../models/document";

async function list(page: number = 1, contractId: number) {
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    if (contractId) {
        queryParams.append('contract_id', contractId.toString());
    }
    const params = {
        params: queryParams
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/documents`, params).then(async (response: AxiosResponse) => {
        const dataPaginated = new DataPaginate(response.data);
        dataPaginated.data = dataPaginated.data.map((note: any) => new Document(note));

        return dataPaginated;
    })
}

export default {
    list
}

