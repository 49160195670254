
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./styles/app.scss";
import LoginPage from "./pages/auth/login-page";
import ValidationCodePage from "./pages/auth/validation-code-page";
import BaseTemplate from "./components/base-template/base-template";
import ListRoadQuotationsPage from "./pages/road-folder/list-road-quotations-page";
import ListContractRoadQuotationPage from "./pages/road-folder/list-contract-road-quotation-page";
import DetailRoadContractPage from "./pages/road-folder/detail-road-contract-page";
import ListInvoicesPage from "./pages/invoices/list-invoices-page";

function App() {
  const router = createBrowserRouter(
    [
      {
        path: '/login',
        element: <LoginPage />
      },
      {
        path: '/validation-code',
        element: <ValidationCodePage />
      },
      {
        path: '/',
        element: <BaseTemplate />,
        children: [
          {
            path: '/',
            element: <Navigate to="/road-quotations" />
          },
          {
            path: 'road-quotations',
            element: <ListRoadQuotationsPage />
          },
          {
            path: 'road-contracts',
            element: <ListContractRoadQuotationPage />
          },
          {
            path: 'road-contracts/:id',
            element: <DetailRoadContractPage />
          },
          {
            path: 'invoices',
            element: <ListInvoicesPage />
          },
          {
            path: 'sentry',
            element: <div><button onClick={() => {throw new Error("This is your first error!");}}>Break the world</button></div>
          }
        ]
      }
    ] 
  )

  return <RouterProvider router={router} />;
}

export default App;
