import axios, { AxiosResponse } from "axios";
import DataPaginate from "../models/utils/data-paginate";
import Facture from "../models/facture";

async function list(page: number = 1, search: string = '', status: string, dateFrom?: string, dateTo?: string) {
    const queryParams = new URLSearchParams();
    queryParams.append('page_size', '25');
    queryParams.append('page', page.toString());
    if (search) {
        queryParams.append('search', search);
    }
    if (status) {
        queryParams.append('status', status);
    }
    if (dateFrom) {
        queryParams.append('date_from', dateFrom);
    }
    if (dateTo) {
        queryParams.append('date_to', dateTo);
    }
   
    const params = {
        params: queryParams
    }

    return await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/client/factures`, params).then(async (response: AxiosResponse) => {
        const dataPaginate = new DataPaginate(response.data);
        dataPaginate.setData(dataPaginate.data.map(item => new Facture(item)));

        return dataPaginate;
    })
}

export default {
    list
}
